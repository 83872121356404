import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import Image from '../../../../app/Profile.jpg';
import SVG2 from '../../../../app/default-small.svg';
import { useLayout } from '../../core'
import axios from 'axios'
import toast from 'react-hot-toast'

// const itemClass = 'ms-1 ms-lg-3'
const itemClass = 'ms-1 ms-md-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const navigate = useNavigate()
  const { config } = useLayout()

  if (!config.app?.header?.display) {
    return null
  }

  const SubmitLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    axios.post('/logout').
      then(res => {
        if (res.data.status === '200') {
          localStorage.removeItem('id');
          localStorage.removeItem('token');
          navigate('/login');
          toast.success(res.data.message);
        }
      });
  }

  return (
    <>
      <div className='app-navbar flex-shrink-0'>

        {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
          <Search />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div id='kt_activities_toggle' className={btnClass}>
            <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
          </div>
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={btnClass}
          >
            <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
          </div>
          <HeaderNotificationsMenu />
        </div> 

        <div className={clsx('app-navbar-item', itemClass)}>
          <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          </div>
        </div> */}

        <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div>

        <div className='app-navbar-item ms-1 ms-md-3' id='kt_header_user_menu_toggle'>
          <div className='cursor-pointer symbol symbol-30px symbol-md-40px'
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img src={Image} alt='user' />
          </div>
          <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <div className='menu-content d-flex align-items-center px-3'>
                <div className='symbol symbol-50px me-5'>
                  <img alt='Logo' src={Image} />
                </div>
                <div className='d-flex flex-column'>
                  <div className='fw-bold d-flex align-items-center fs-5'>
                    Max Smith
                    <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>
                      Pro
                    </span>
                  </div>
                  <Link to='#' className='fw-semibold text-muted text-hover-primary fs-7'>
                    max@kt.com
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className='menu-item px-5'>
              <Link to='#' className='menu-link px-5'>
                My Profile
              </Link>
            </div>
            <div className='menu-item px-5'>
              <Link to='#' className='menu-link px-5'>
                My Statements
              </Link>
            </div> */}
            <div className='menu-item px-5'>
              <Link to='#' className='menu-link px-5' onClick={SubmitLogout}>
                Sign Out
              </Link>
            </div>
          </div>
        </div>

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div> */}
      </div>
    </>
  )
}

export default Navbar;
