import { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const AuthLayout = () => {
  const [isResponsiveView, setIsResponsiveView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setIsResponsiveView(true);
      } else {
        setIsResponsiveView(false);
      }
    };

    handleResize(); // Check initial window width
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <Link to='#' className='px-5' target='_blank'>
              Terms
            </Link>

            <Link to='#' className='px-5' target='_blank'>
              Plans
            </Link>

            <Link to='#' className='px-5' target='_blank'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
          </Link>
          {!isResponsiveView && (
            <>
              <img
                className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
                src={toAbsoluteUrl('/media/misc/auth-screens.png')}
                alt=''
              />
              <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
                Fast, Efficient and Productive
              </h1>
              <div className='text-white fs-base text-center'>
                In this kind of post,{' '}
                <Link to='#' className='opacity-75-hover text-warning fw-bold me-1'>
                  the blogger
                </Link>
                introduces a person they've interviewed <br /> and provides some background information
                about
                <Link to='#' className='opacity-75-hover text-warning fw-bold me-1'>
                  the interviewee
                </Link>
                and their <br /> work following this is a transcript of the interview.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
