import React, { ChangeEvent, useState, useEffect } from 'react'
import clsx from 'clsx'
import { IResolveParams } from 'reactjs-social-login'
import { Link, useNavigate } from 'react-router-dom'
import GoogleLogin from './GoogleLogin';
import LinkedInLogin from './LinkedInLogin';
import axios from 'axios'
import toast from 'react-hot-toast'

const Login = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [touched, setTouched] = useState(false)
  const [method, setMethod] = useState('1');

  const [loginInput, setLogin] = useState({
    email: '',
  })

  const [error, setError] = useState({
    email: '',
  })

  useEffect(() => {
    if (touched) {
      setError({ email: '' })
      setMessage('')
    }
  }, [touched])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
    setTouched(true);
  }

  const handleGoogleLogin = async ({ data }: IResolveParams) => {
    try {
      if (data && data.email && data.access_token) {
        const email = data.email; // Access the email field from the data object
        const access_token = data.access_token; // Access the access_token field from the data object
        const Data = {
          email: email, // Include the email in API call
          access_token: access_token, // Include the access_token in API call
          method: method // Use the dynamic id value
        };

        axios.post('/register', Data)
          .then(async (res) => {
            if (res.data.status === '200') {
              localStorage.setItem('id', res.data.id);
              localStorage.setItem('token', access_token);
              const promise = toast.success(res.data.message)
              await promise
              setTimeout(() => {
                navigate('/set-domain');
              }, 1800)
              setMessage('');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error('No email received from Google');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: loginInput.email,
      error: [],
      method: '2',
      ipAddress: '',
    }

    axios.post('/login', data).then(async (res) => {
      if (res.data.status === '200') {
        const promise = toast.success(res.data.message)
        alert(res.data.message);
        await promise
        setTimeout(() => {
          navigate('/one-time-password')
        }, 2000)
        setMessage('');
      } else if (res.data.status === '401') {
        setMessage(res.data.message);
        setError({ email: '' });
        // invalid details ni jagya e invalid request token check ma register vahte aave ema
      } else if (res.data.status === '400') {
        setError(res.data.errors);
        setMessage('');
      }
      setLoading(false);
    })
  }

  return (
    <>

      {message && (
        <div className='alert alert-info mb-10 text-center fs-5 text-success' role='alert'>
          {message}
        </div>
      )}

      <form className='form w-100' onSubmit={handleSubmit} id='' noValidate>
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
        </div>

        <div className='row mb-1'>
          <div className='col-md-6'>
            <div className='col-12'>
              <GoogleLogin handleGoogleLogin={handleGoogleLogin} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='col-12'>
              <LinkedInLogin />
            </div>
          </div>
        </div>

        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input type='email' placeholder='Email' autoComplete='off' name='email' onChange={handleInput} className={clsx('form-control bg-transparent')} />
          <span className='text-danger'>{error.email}</span>
        </div>

        <div className='d-grid mb-10'>
          <button type='submit' className='btn btn-primary'>
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && <span className='d-block indicator-progress'>Verifying...</span>}
          </button>
        </div>

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a Member yet?{' '}
          <Link to='/register' className='link-primary'>
            Sign Up
          </Link>
        </div>
      </form>
    </>
  );
}

export default Login;
