import React, { ChangeEvent, useState, useEffect } from 'react'
import clsx from 'clsx'
import LinkedInIcon from '../../../../linkedin-icon.png';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Button } from 'react-bootstrap';
import countriesData from '../../../../country.json';

const Test = () => {

  const [productInput, setProduct] = useState({
    domain: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    city: '',
    postcode: '',
    country: '',
    state: '',
    street_1: '',
    street_2: '',
    plan_id: '',
    card_name: '',
    card_number: '',
    cvv: '',
    expiry: '',
  });

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [filterCountry, setFilterCountry] = useState('');
  const [searchCountry, setSearchCountry] = useState('');

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setSelectedState('');
    setProduct((prevState) => ({ ...prevState, country: selectedCountry, state: '' }));
  };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    setSelectedState(selectedState);
    setProduct((prevState) => ({ ...prevState, state: selectedState }));
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    setProduct({ ...productInput, [e.target.name]: e.target.value });
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredCountries = countriesData?.countries.filter((country) =>
    country.country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCountryData = countriesData.countries.filter((country: any) =>
    country.country.toLowerCase().includes(filterCountry.toLowerCase())
  );

  return (
    <>

      <div className='d-flex flex-column flex-column-fluid'>
        <div className="card mb-5 mb-xl-10 col-lg-10 offset-lg-1">
          <div className="card-header">
            <div className="card-title">
              <h3>Billing Address Information</h3>
            </div>
          </div>
          <div className="card-body">
            <div className='row'>
              <div className="fv-row mb-10 col-md-6">
                <label className="form-label required">First Name</label>
                <input name="first_name" className="form-control form-control-lg form-control-solid" value={productInput.first_name} required />
              </div>
              <div className="fv-row mb-10 col-md-6">
                <label className="form-label required">Last Name</label>
                <input name="last_name" className="form-control form-control-lg form-control-solid" value={productInput.last_name} required />
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="fs-6 fw-semibold form-label required">Phone Number</label>
              <input name="phone_number" className="form-control form-control-lg form-control-solid" value={productInput.phone_number} required />
            </div>
            <div className='row'>
              <div className="fv-row mb-10 col-md-6">
                <label className="form-label required">Street 1</label>
                <textarea name="street_1" className="form-control form-control-lg form-control-solid" rows={3} value={productInput.street_1} onChange={handleTextAreaChange} required ></textarea>
              </div>
              <div className="fv-row mb-10 col-md-6">
                <label className="form-label">Street 2</label>
                <textarea name="street_2" className="form-control form-control-lg form-control-solid" rows={3} value={productInput.street_2} onChange={handleTextAreaChange}></textarea>
              </div>
              <div className="fv-row mb-10 col-md-6">
                <label className="form-label required">City</label>
                <input name="city" className="form-control form-control-lg form-control-solid" value={productInput.city} required />
              </div>

              <div className="d-flex flex-column mb-5 fv-row">
                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="required">Position</span>
                  <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
                </label>
                <select name="position" data-control="select2" data-placeholder="Select a position..." className="form-select form-select-solid">
                  <option value="Web Developer">Web Developer</option>
                  <option value="Web Designer">Web Designer</option>
                  <option value="Art Director">Art Director</option>
                  <option value="Finance Manager">Finance Manager</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="System Administrator">System Administrator</option>
                </select>
              </div>
            </div>

            <div className="fv-row mb-10 col-md-6">
              <label className="form-label required">Postcode</label>
              <input name="postcode" className="form-control form-control-lg form-control-solid" value={productInput.postcode} required />
            </div>
          </div>
          <div className="d-flex flex-stack justify-content-center pt-5 mb-2">
            <button type="submit" className="btn btn-lg btn-primary fs-4">
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;


{/* <div classNameName="menu-item px-3 my-0">
    <Link to="#" classNameName={`menu-link px-3 py-2 ${theme === 'system' ? 'active' : ''}`} data-kt-element="mode" data-kt-value="system" onClick={() => handleThemeChange('system')}>
        <span classNameName="menu-icon" data-kt-element="icon">
            <span classNameName="svg-icon svg-icon-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z"
                        fill="currentColor" />
                </svg>
            </span>
        </span>
        <span classNameName="menu-title">System</span>
    </Link>
                <span className="select2-container select2-container--bootstrap5 select2-container--open" style={{ position: 'absolute', top: '142.719px', left: '22.75px' }}>
                  <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '254.5px' }}>
                    <span className="select2-search select2-search--dropdown">
                      <input className="select2-search__field" type="search" tabIndex={0} autoCorrect="off" autoCapitalize="none" spellCheck="false" role="searchbox" aria-autocomplete="list" autoComplete="off" aria-label="Search" aria-controls="select2-2vs6-results" aria-activedescendant="select2-2vs6-result-lopd-1" />
                    </span>
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox" id="select2-2vs6-results" aria-expanded="true" aria-hidden="false">
                        <li className="select2-results__option select2-results__option--selectable select2-results__option--highlighted" id="select2-2vs6-result-lopd-1" role="option" data-select2-id="select2-data-select2-2vs6-result-lopd-1" aria-selected="true">Approved</li>
                        <li className="select2-results__option select2-results__option--selectable" id="select2-2vs6-result-8a87-2" role="option" data-select2-id="select2-data-select2-2vs6-result-8a87-2" aria-selected="false">Pending</li>
                        <li className="select2-results__option select2-results__option--selectable" id="select2-2vs6-result-7bea-2" role="option" data-select2-id="select2-data-select2-2vs6-result-7bea-2" aria-selected="false">In Process</li>
                        <li className="select2-results__option select2-results__option--selectable" id="select2-2vs6-result-3xxi-2" role="option" data-select2-id="select2-data-select2-2vs6-result-3xxi-2" aria-selected="false">Rejected</li>
                      </ul>
                    </span>
                  </span>
                </span>
</div> */}
