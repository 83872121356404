import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useNavigate, Navigate } from 'react-router-dom';
import clsx from "clsx";
import axios from "axios";
import toast from 'react-hot-toast';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';

const NewPassword = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        password: '',
        password_confirmation: '',
        token: '',
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
        const token = localStorage.getItem('token');
        if (token) {
            setFormData(prevState => ({ ...prevState, token }));
        } else {
            navigate('/auth/login');
        }
    }, [])

    const [error, setError] = useState({
        password: '',
        password_confirmation: '',
    });

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            password: formData.password,
            password_confirmation: formData.password_confirmation,
            token: formData.token,
        }

        axios.post('/set-password', data)
            .then(res => {
                console.log(res);
                if (res.data.status == '1') {
                    // localStorage.getItem('token', res.data.tokenData);
                    navigate('/auth/login');
                    toast.success(res.data.message);
                }
                else if (res.data.status === '400') {
                    setError(res.data.errors);
                    // console.log(error);
                }
                setLoading(false);
            });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={SubmitForm} noValidate>

                <div className="text-center mb-10">
                    <h1 className="text-dark fw-bolder mb-3">Set New Password</h1>
                    <div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
                        <Link to="/auth/login" className="link-primary fw-bold fs-6 ms-1">Sign in</Link>
                    </div>
                </div>

                <div className='fv-row mb-5' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                        <div className='position-relative mb-3 d-flex align-items-center'>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Password' autoComplete='off' name='password' onChange={handleInput}
                                className={clsx('form-control bg-transparent', 'pr-5')} />
                            <button type="button" className="btn btn-sm btn-icon position-absolute top-50 translate-middle-y end-0" onClick={togglePasswordVisibility}>
                                {showPassword ? <i className='bi bi-eye fs-2'></i> : <i className='bi bi-eye-slash fs-2'></i>}
                            </button>
                        </div>
                        <div className='mb-2'>
                            <span className="text-danger">{error.password}</span>
                        </div>
                        <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        </div>
                    </div>
                    <div className='text-muted'>
                        Use 6 or more characters with a mix of letters, numbers & symbols.
                    </div>
                </div>

                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                    <input type='password' placeholder='Confirm Password' autoComplete='off' name='password_confirmation' onChange={handleInput}
                        className={clsx('form-control bg-transparent')} />
                    <div className='mt-2'>
                        {Array.isArray(error.password_confirmation) && error.password_confirmation.map((errorMessage: string, index: number) => (
                            <div key={index} className="d-block text-danger">{errorMessage}</div>
                        ))}
                    </div>
                </div>

                <div className='text-center'>
                    <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' id='kt_sign_up_submit' >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                            <span className='indicator-progress d-block'>
                                Please wait...
                            </span>
                        )}
                    </button>
                </div>

            </form>
        </>
    );
}

export default NewPassword;