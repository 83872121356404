import React from "react";
import { Link } from "react-router-dom";
// import { Card, Button } from "react-bootstrap";

const Thanks = () => {

    return (

        <div className="jumbotron text-center">
            <h1 className="display-3 mb-5" style={{ marginTop: '15%' }}>Congratulations!</h1>
            <p className="lead mb-2">
                <strong>Your Account Verification has been Completed.</strong>
                <br />
            </p>
            <p className="lead mb-5">
                <Link to="/login">Login</Link> <strong>to Proceed Further...</strong>
            </p>
            <hr />
            <p className="lead">
                <strong>Having trouble?</strong> <Link to="/contact">Contact Us</Link>
            </p>
        </div>

        // <div className="container mt-5">
        //     <Card>
        //         <Card.Body>
        //             <Card.Title>Congratulations!</Card.Title>
        //             <Card.Text>
        //                 Your Account Verification has been Completed. Login to Proceed
        //                 Further...
        //             </Card.Text>
        //             <Link to="/login">
        //                 <Button variant="primary">Login</Button>
        //             </Link>
        //             <hr />
        //             <Card.Text>
        //                 Having trouble? <Link to="/contact">Contact Us</Link>
        //             </Card.Text>
        //         </Card.Body>
        //     </Card>
        // </div>

    );
}

export default Thanks;