/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import SVG1 from '../../../../app/default-dark.svg'
import SVG2 from '../../../../app/default-small.svg'
import { useLayout } from '../../core'
import Header from './Header';
import Navbar from './Navbar';
// import Navbar from '../../../../app/modules/auth/components/Navbar';

const HeaderWrapper = () => {

  const navigate = useNavigate();
  const { config, classes } = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container container-fluid d-flex align-items-stretch justify-content-between',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {/* {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='#' className='d-lg-none'>
                  <img alt='Logo' src={SVG2} className='h-30px' />
                </Link>
              </div>
            </div>
          </>
        )} */}
        <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
          <Link to='#'>
            <img alt='Logo' src={SVG1} className='h-25px app-sidebar-logo-default' />
            <img alt='Logo' src={SVG2} className='h-20px app-sidebar-logo-minimize' />
          </Link>
        </div>
        {config.app.header?.default?.menu?.display && (
          <div className='d-flex align-items-center d-lg-none ms-1 ms-md-2 me-n3'>
            <div className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-35px h-md-35px'
              id='kt_app_header_menu_toggle' >
              <span className="svg-icon svg-icon-2 svg-icon-md-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                  <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                </svg>
              </span>
            </div>
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='#' className='d-lg-none'>
                <img alt='Logo' src={SVG2} className='h-30px' />
              </Link>
            </div>
          </div>
        )}
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}

export default HeaderWrapper;
