import { MenuItem } from './MenuItem'

const MenuInner = () => {

  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuItem title='Plans' to='/plan' />
      <MenuItem title='Invoice' to='/invoice' />
      <MenuItem title='Bill Address' to='/bill' />
    </>
  );
}

export default MenuInner;
