
import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator'
import DomainConfiguration from '../modules/auth/components/DomainConfiguration';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Dashboard from '../modules/auth/components/Dashboard';
import Billing from '../modules/auth/components/Billing';
import Plan from '../modules/auth/components/Plan';
import Invoice from '../modules/auth/components/Invoice';
// import { Overview } from '../modules/accounts/components/Overview';

const PrivateRoutes = () => {

  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (id && token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='set-domain' element={<DomainConfiguration />} />

          <Route path='bill'
            element={
              <SuspensedView>
                <Billing />
              </SuspensedView>
            }
          />
          <Route path='plan'
            element={
              <SuspensedView>
                <Plan />
              </SuspensedView>
            }
          />
          <Route path='invoice'
            element={
              <SuspensedView>
                <Invoice />
              </SuspensedView>
            }
          />

        </Route>
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default PrivateRoutes;
