import React, { useState, ChangeEvent } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SetPassword = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [registerPassword, SetYourPassword] = useState({
    password: '',
    password_confirmation: '',
    error: [],
  });
  const [error, setError] = useState({
    password: '',
    password_confirmation: '',
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    SetYourPassword({ ...registerPassword, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      password: registerPassword.password,
      password_confirmation: registerPassword.password_confirmation,
      error: [],
    }

    axios.post("/register", data)
      .then(res => {
        if (res.data.status === '200') {
          localStorage.setItem('auth_id', res.data.id);
          navigate('/auth/login');
        }
        else if (res.data.status === '400') {
          setError(res.data.errors);
        }
        setLoading(false);
      });
  }

  return (

    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit} noValidate>

      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Set Password</h1>
        <div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
          <Link to="/auth/login" className="link-primary fw-bold fs-6 ms-1">Sign in</Link>
        </div>
      </div>

      <div className='fv-row mb-5' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3 d-flex align-items-center'>
            <input type={showPassword ? 'text' : 'password'} placeholder='Password' autoComplete='off' name='password' onChange={handleInput}
              className={clsx('form-control bg-transparent', 'pr-5')} />
            <button type="button" className="btn btn-sm btn-icon position-absolute top-50 translate-middle-y end-0" onClick={togglePasswordVisibility}>
              {showPassword ? <i className='bi bi-eye fs-2'></i> : <i className='bi bi-eye-slash fs-2'></i>}
            </button>
          </div>
          <div className='mb-2'>
            <span className="text-danger">{error.password}</span>
          </div>
          <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input type='password' placeholder='Password confirmation' autoComplete='off' name='password_confirmation' onChange={handleInput}
          className={clsx('form-control bg-transparent')} />
        <div className='mt-2'>
          <span className="text-danger">{error.password_confirmation}</span>
        </div>
      </div>

      <div className='text-center'>
        <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' id='kt_sign_up_submit' >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
            </span>
          )}
        </button>
      </div>

    </form>
  );
}

export default SetPassword;

// <div className='fv-row mb-8' data-kt-password-meter='true'>
// <div className='mb-1'>
//     <label className='form-label fw-bolder text-dark fs-6'>Password</label>
//     <div className='position-relative mb-3'>
//         <input type={showPassword ? 'text' : 'password'} placeholder='Password' autoComplete='off' name='password'
//             className={clsx('form-control bg-transparent', 'pr-5')} />
//          <button type="button" className="btn btn-sm btn-icon position-absolute top-50 translate-middle-y end-0" onClick={togglePasswordVisibility}>
//             {showPassword ? <i className='bi bi-eye fs-2'></i> : <i className='bi bi-eye-slash fs-2'></i> }
//         </button>
//         {/* <span className="text-danger">{error.password}</span> */}
//     </div>
//     <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
//         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
//     </div>
// </div>
// <div className='text-muted'>
//     Use 8 or more characters with a mix of letters, numbers & symbols.
// </div>
// </div>

// <div className='fv-row mb-5'>
// <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
// <input type='password' placeholder='Password confirmation' autoComplete='off' name='password_confirmation'
//     className={clsx('form-control bg-transparent')} />
// {/* <span className="text-danger">{error.password_confirmation}</span> */}
// </div>

//     <div className="d-flex flex-column flex-root">
//     <div className="d-flex flex-column flex-lg-row flex-column-fluid">
//         <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
//             <div className="d-flex flex-center flex-column flex-lg-row-fluid">
//                 <div className="w-lg-500px p-10">
//                     <form className="form w-100" noValidate>
//                         <div className="text-center mb-10">
//                             <h1 className="text-dark fw-bolder mb-3">Set New Password</h1>
//                             <div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
//                                 <Link to="/register" className="link-primary fw-bold fs-6 ms-1">Sign in</Link>
//                             </div>
//                         </div>
//                         <div className="fv-row mb-8" data-kt-password-meter="true">
//                             <div className="mb-1">
//                                 <div className="position-relative mb-3">
//                                     <input className="form-control bg-transparent" placeholder="Password" name="password" autoComplete='off' />
//                                     <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">

//                                     </span>
//                                 </div>
//                                 <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
//                                     <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
//                                     <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
//                                     <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
//                                     <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
//                                 </div>
//                                 {/* <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div> */}
//                             </div>
//                             <div className="fv-row mb-8">
//                                 <input type="password" placeholder="Confirm Password" name="confirm_password" autoComplete='off' className="form-control bg-transparent" />
//                             </div>
//                             <div className="fv-row mb-8">
//                                 <label className="form-check form-check-inline">
//                                     <input className="form-check-input" type="checkbox" name="toc" checked disabled />
//                                     <span className="form-check-label fw-semibold text-gray-700 fs-6 ms-1">I Agree to
//                                         <Link to="#" className="ms-1 link-primary">Terms and conditions</Link>.</span>
//                                 </label>
//                             </div>
//                             <div className="d-grid mb-10">
//                                 <button type="submit" className="btn btn-primary">
//                                     <span className="indicator-label">Confirm</span>
//                                     <span className="indicator-progress">Please wait...
//                                         <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
//                                 </button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>