

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const TokenCheck = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const getIPAddress = async () => {
        const response = await fetch('https://fruitbox-backend.laraveldev.work/api/');
        const data = await response.json();
        return data.IPv4;
    };

    useEffect(() => {
        const data = {
            ip_address: ''
        }

        axios.post(`/emailverify/token?token=${token}`, data)
            .then(async res => {
                if (res.data.status == '200') {
                    const promise = toast.success(res.data.message);
                    await promise;
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
                else {
                    const promise = toast.error(res.data.message);
                    await promise;
                    setTimeout(() => {
                        navigate('/register');
                    }, 2000);
                }
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
                <div className="flex-fill text-center fs-5">
                    <p>Loading...</p>
                </div>
            </div>
        );
    }

    return null;

}

export default TokenCheck;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams, Navigate } from 'react-router-dom';
// import axios from 'axios';
// import toast from 'react-hot-toast';  jdjaydeep489@gmail.com

// const TokenCheck = () => {
//     const { token } = useParams();
//     const navigate = useNavigate();
//     const [isLoading, setIsLoading] = useState(true);
//     const [showSuccess, setShowSuccess] = useState(false);


//     const getIPAddress = async () => {
//         const response = await fetch('https://fruitbox-backend.laraveldev.work/api/');
//         const data = await response.json();
//         return data.IPv4;
//     };

//     useEffect(() => {

//         getIPAddress().then((ipAddress) => {
//             const data = {
//                 ip_address: ipAddress
//             }

//             axios.post(`/emailverify/token?token=${token}`, data)
//                 .then(async res => {
//                     if (res.data.status == '200') {
//                         setIsLoading(false);
//                         setShowSuccess(true);
//                         navigate('/check-domain');
//                     }
//                     else {
//                         const promise = toast.error(res.data.message);
//                         await promise;
//                         setIsLoading(false);
//                         setTimeout(() => {
//                             navigate('/auth/register');
//                         }, 2500);
//                     }
//                 });
//         });
//     }, []);

//     if (isLoading) {
//         <div className="d-flex align-items-center justify-content-center" style={{ height: "80vh" }}>
//             <div className="flex-fill text-center fs-5">
//                 <p>Loading...</p>
//             </div>
//         </div>
//     }

//     if (showSuccess) {
//         return (
//             <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
//                 <div className="flex-fill text-center fs-5">
//                     <p>
//                         Your email has been successfully verified
//                         <br />
//                         Redirecting to Domain Page
//                     </p>
//                 </div>
//             </div>
//         );
//     }

//     return null;
// }

// export default TokenCheck;

    // import React, { useState, useEffect } from 'react';
    // import { useNavigate, Navigate } from 'react-router-dom';
    // import axios from 'axios';

    // const TokenCheck = () => {
    //     const navigate = useNavigate();
    //     const [isLoading, setIsLoading] = useState(true);

    //     // useEffect(() => {
    //     //     const timeout = setTimeout(() => {
    //     //         setIsLoading(false);
    //     //     }, 2000);
    //     //     return () => clearTimeout(timeout);
    //     // }, []);

    //     useEffect(() => {
    //         axios.post(`/emailverify/token?token=${token}`)
    //             .then(res => {
    //                 if (res.data.status === '200') {
    //                     setIsLoading(false);
    //                 }
    //             })
    //     }, []);

    //     if (isLoading) {
    //         return (
    //             <div className="d-flex align-items-center justify-content-center" style={{ height: "80vh" }}>
    //                 <div className="flex-fill text-center fs-5">
    //                     <p>Loading...</p>
    //                 </div>
    //             </div>
    //         );
    //     }

    // const token = localStorage.getItem('token');
    // if (!token) {
    //     return <Navigate to='/auth/register' />;
    // }
    // else {
    //     return <Navigate to='/check-domain' />;
    // }
