import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios'

const Plan = () => {

    const [selectedPlan, setSelectedPlan] = useState<string>('1');
    const [selectedDuration, setSelectedDuration] = useState<string>('month');
    const [planOptions, setPlanOptions] = useState([
        {
            sku: '',
            name: '',
            // description: '',
            // priceMonth: '',
            // priceAnnual: '',
        }
    ]);

    useEffect(() => {
        axios.get('/get-plan')
            .then(res => {
                if (res.data.status === '200') {
                    const parsedData = JSON.parse(res.data.data);
                    const products = parsedData.data.productDetail.items;
                    setPlanOptions(products);
                    // if (products.length > 0) {
                    //     setProductChecked(products[0].name);
                    // }
                    if (products.length > 0) {
                        const defaultProductName = "Fruit Product"; // Specify the default product name here
                        const defaultProduct = products.find((product: { sku: string; name: string }) => product.name === defaultProductName);
                        if (defaultProduct) {
                            setSelectedPlan(defaultProduct.name);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handlePlanChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedPlan(e.target.value);
    };

    const handleDurationChange = (duration: string) => {
        setSelectedDuration(duration);
    };

    const getPlanPrice = (plan: any) => {
        return selectedDuration === 'month' ? plan.priceMonth : plan.priceAnnual;
    };

    return (
        <>
            <div className='card mt-4 mb-xl-8 col-lg-8 offset-lg-2'>
                <div className='card-body px-0'>
                    <div className="pt-0 px-5 px-xl-20">
                        <div className="mb-13 text-center">
                            <h1 className="mb-3">Plan List</h1>
                        </div>
                        <div className="d-flex flex-column flex-column-fluid mt-5">
                            <div className="nav-group nav-group-outline mx-auto" data-kt-buttons="true">
                                <button
                                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${selectedDuration === 'month' ? 'active' : ''
                                        }`}
                                    data-kt-plan="month"
                                    onClick={() => handleDurationChange('month')}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 ${selectedDuration === 'annual' ? 'active' : ''
                                        }`}
                                    data-kt-plan="annual"
                                    onClick={() => handleDurationChange('annual')}
                                >
                                    Annual
                                </button>
                            </div>
                            <div className="row mt-10">
                                <div className="col-lg-6 mx-auto">
                                    <div className="nav flex-column">
                                        {planOptions.map((plan, index) => (
                                            <label
                                                key={plan.sku}
                                                className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 ${selectedPlan === plan.sku ? 'active' : ''} mb-6`}
                                                data-bs-toggle="tab"
                                                data-bs-target={`#kt_upgrade_plan_${plan.sku}`}
                                            >
                                                <div className="d-flex align-items-center me-2">
                                                    <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`plan_${index + 1}`}
                                                            value={plan.sku}
                                                            checked={selectedPlan === plan.sku}
                                                            onChange={handlePlanChange}
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">{plan.name}</div>
                                                        <div className="fw-semibold opacity-75">Plan description</div>
                                                        {/* <div className="fw-semibold opacity-75">{plan.description}</div> */}
                                                    </div>
                                                </div>
                                                <div className="ms-5">
                                                    <span className="mb-2">$</span>
                                                    <span className="fs-3x fw-bold" data-kt-plan-price-month="39" data-kt-plan-price-annual="399">
                                                        39
                                                    </span>
                                                    {/* <span
                                                className="fs-3x fw-bold"
                                                data-kt-plan-price-month={plan.priceMonth}
                                                data-kt-plan-price-annual={plan.priceAnnual}
                                            >
                                                {getPlanPrice(plan)}
                                            </span> */}
                                                    <span className="fs-7 opacity-50">
                                                        /<span data-kt-element="period">Monthly</span>
                                                        {/* /<span data-kt-element="period">{selectedDuration === 'month' ? 'Monthly' : 'Annual'}</span> */}
                                                    </span>
                                                </div>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-center flex-row-fluid pt-9">
                            <button type="reset" className="btn btn-lg btn-light me-3">
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-lg btn-primary" id="kt_modal_upgrade_plan_btn">
                                <span className="indicator-label">Update Plan</span>
                                <span className="indicator-progress">
                                    Please wait...
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Plan;