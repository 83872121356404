import React from 'react';
import { LoginSocialGoogle } from 'reactjs-social-login';
import GoogleIcon from '../../../../google-icon.svg';


const GoogleLogin = ({ handleGoogleLogin }: { handleGoogleLogin: any }) => {

    return (
        <LoginSocialGoogle
            client_id={'110085234015-ultiouli0665t82kekv78qs6jf60l00c.apps.googleusercontent.com'}
            scope='openid profile email'
            discoveryDocs='claims_supported'
            access_type='offline'
            onResolve={handleGoogleLogin}
            onReject={(err) => { }}
        >
            <div className='row g-3 mb-9'>
                <button
                    className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    type='button'
                >
                    <img alt='Logo' src={GoogleIcon} className='h-15px me-3' />
                    Log in with Google
                </button>
            </div>
        </LoginSocialGoogle>
    );
};

export default GoogleLogin;