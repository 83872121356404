import React, { useState, ChangeEvent, useEffect } from 'react'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'

const Otp = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState('')

  const [error, setError] = useState({
    otp: [],
    // token: '',
  })

  const [message, setMessage] = useState('')
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (touched) {
      setError({ otp: [] })
    }
  }, [otp, touched])

  const handleOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setOtp(e.target.value)
    setTouched(true)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    axios.post('/login-otp-verify', { otp: otp }).then(async (res) => {
      if (res.data.status === '200') {
        localStorage.setItem('id', res.data.id)
        localStorage.setItem('token', res.data.token)
        const promise = toast.success(res.data.message)
        await promise
        setTimeout(() => {
          navigate('/set-domain')
        }, 2000)
        setMessage('')
        setError({ otp: [] })
      } else if (res.data.status === '400') {
        setError(res.data.errors)
        setMessage('')
      } else if (res.data.status === '401') {
        setMessage(res.data.message)
        setError({ otp: [] })
      }
      setLoading(false)
    })
  }

  return (
    <>
      {message && (
        <div className='alert alert-info mb-10 text-center fs-5 text-success' role='alert'>
          {message}
        </div>
      )}
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your OTP to Create your new password
          </div>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
          <input
            type='text'
            autoComplete='off'
            placeholder='Enter OTP'
            name='otp'
            value={otp}
            onChange={handleOtp}
            className={clsx('form-control bg-transparent')}
          />
          <span className='text-danger error'>{error.otp}</span>
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link to='/login'>
            <button type='button' className='btn btn-light me-4'>
              Cancel
            </button>
          </Link>
          <button type='submit' className='btn btn-primary'>
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && <span className='d-block indicator-progress'> Please wait... </span>}
          </button>
        </div>
      </form>
    </>
  )
}

export default Otp;

// const togglePasswordVisibility = () => {
//   setShowPassword(!showPassword);
// };

// if (formSubmitted) {
//   return (
//     <div>
//       <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit} noValidate>

//         <div className="text-center mb-10">
//           <h1 className="text-dark fw-bolder mb-3">Set New Password</h1>
//           <div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
//             <Link to="/auth/login" className="link-primary fw-bold fs-6 ms-1">Sign in</Link>
//           </div>
//         </div>

//         <div className='fv-row mb-5' data-kt-password-meter='true'>
//           <div className='mb-1'>
//             <label className='form-label fw-bolder text-dark fs-6'>Password</label>
//             <div className='position-relative mb-3 d-flex align-items-center'>
//               <input type={showPassword ? 'text' : 'password'} placeholder='Password' autoComplete='off' name='password' onChange={handleInput}
//                 className={clsx('form-control bg-transparent', 'pr-5')} />
//               <button type="button" className="btn btn-sm btn-icon position-absolute top-50 translate-middle-y end-0" onClick={togglePasswordVisibility}>
//                 {showPassword ? <i className='bi bi-eye fs-2'></i> : <i className='bi bi-eye-slash fs-2'></i>}
//               </button>
//             </div>
//             <div className='mb-2'>
//               <span className="text-danger">{valid.password}</span>
//             </div>
//             <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
//               <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//               <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//               <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
//               <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
//             </div>
//           </div>
//           <div className='text-muted'>
//             Use 8 or more characters with a mix of letters, numbers & symbols.
//           </div>
//         </div>

//         <div className='fv-row mb-5'>
//           <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
//           <input type='password' placeholder='Confirm Password' autoComplete='off' name='password_confirmation' onChange={handleInput}
//             className={clsx('form-control bg-transparent')} />
//           <div className='mt-2'>
//             <span className="text-danger">{valid.password_confirmation}</span>
//           </div>
//         </div>

//         <div className='text-center'>
//           <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' id='kt_sign_up_submit' >
//             {!loading && <span className='indicator-label'>Submit</span>}
//             {loading && (
//               <span className='indicator-progress' style={{ display: 'block' }}>
//                 Please wait...
//               </span>
//             )}
//           </button>
//         </div>

//       </form>
//     </div>
//   );
// }
