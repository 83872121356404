
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { IResolveParams } from 'reactjs-social-login';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import GoogleLogin from './GoogleLogin';
import LinkedInLogin from './LinkedInLogin';
import axios from 'axios';
import toast from 'react-hot-toast'

const Registration = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState(false);
  const [method, setMethod] = useState('1');

  const [registerInput, setRegister] = useState({
    email: '',
    error: [],
  });

  const [error, setError] = useState({
    email: '',
  });

  const topRef = useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (registerInput.email && touched) {
      setError({ email: "" });
      setMessage("");
    }
  }, [registerInput.email, touched]);

  useEffect(() => {
    if (message) {
      topRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [message]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
    setTouched(true);
  };

  const handleGoogleLogin = async ({ data }: IResolveParams) => {
    try {

      if (data && data.email && data.access_token) {
        const email = data.email; // Access the email field from the data object
        const access_token = data.access_token; // Access the access_token field from the data object
        const Data = {
          email: email, // Include the email in API call
          access_token: access_token, // Include the access_token in API call
          method: method // Use the dynamic id value
        };

        axios.post('/register', Data)
          .then(async (res) => {
            if (res.data.status === '200') {
              localStorage.setItem('id', res.data.id);
              localStorage.setItem('token', access_token);
              const promise = toast.success(res.data.message);
              await promise
              setTimeout(() => {
                navigate('/set-domain')
              }, 1800)
              setMessage('');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error('No email received from Google');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: registerInput.email,
      method: '2',
      error: [],
    }

    axios.post("/register", data)
      .then(res => {
        if (res.data.status === '200') {
          localStorage.setItem('id', res.data.id);
          toast.success(res.data.message);
        }
        else if (res.data.status === '400') {
          setError(res.data.errors);
          setMessage('');
        }
        setLoading(false);
      });
  }

  return (
    <>
      {message && (
        <div className='alert alert-info mb-10 text-center fs-5 text-success' role='alert'>
          {message}
        </div>
      )}

      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit} noValidate>

        <div ref={topRef} className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
        </div>

        <div className='row mb-1'>
          <div className='col-md-6'>
            <div className='col-12'>
              <GoogleLogin handleGoogleLogin={handleGoogleLogin} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='col-12'>
            <LinkedInLogin />
            </div>
          </div>
        </div>

        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input type='email' placeholder='Email' autoComplete='off' name='email' onChange={handleInput}
            className={clsx('form-control bg-transparent')} value={registerInput.email} ref={emailInputRef} />
          <span className="text-danger">{error.email}</span>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' >
            <input type='checkbox' className='form-check-input' checked disabled />
            <span>
              I Accept to
              <Link to='#' target='_blank' className='ms-1 link-primary'>
                Terms & Conditions
              </Link>
            </span>
          </label>
        </div>

        <div className='text-center'>
          <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' id='kt_sign_up_submit' >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
              </span>
            )}
          </button>
        </div>

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Already have an account?{' '}
          <Link to='/login' className='link-primary'>
            Sign In
          </Link>
        </div>

      </form>
    </>
  );
}

export default Registration;
