import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import countriesData from '../../../../country.json';
import countryCodes from '../../../../country_phone_code.json';
import Select from 'react-select';
import axios from "axios";
import toast from 'react-hot-toast'

const Billing = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [dialCode, setDialCode] = useState('');

    const [address, setAddress] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        street_1: '',
        street_2: '',
        city: '',
        country: '',
        state: '',
        postcode: '',
    });

    const [error, setError] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        street_1: '',
    });

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = e.target.value;
        setSelectedCountry(selectedCountry);
        setSelectedState('');
        setAddress((prevState) => ({ ...prevState, country: selectedCountry, state: '' }));
    };

    const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedState = e.target.value;
        setSelectedState(selectedState);
        setAddress((prevState) => ({ ...prevState, state: selectedState }));
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setAddress({ ...address, [e.target.name]: e.target.value });
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.persist();
        setAddress({ ...address, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const userToken = localStorage.getItem('token');
        axios.get('/v1/vendor/edit', {
            headers: {
                Authorization: `Bearer ${userToken}` // Include the token in the request headers
            }
        })
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data.state);
                    setAddress(res.data.data);
                }
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getDialCodeFromCountryCode().then((code) => {
            setDialCode(code);
        });
    }, []);

    const getIPAddress = async () => {
        const response = await fetch('https://fruitbox-backend.laraveldev.work/api/');
        const data = await response.json();
        return data.country_code;
    };

    const getDialCodeFromCountryCode = async () => {
        const countryCode = await getIPAddress();
        const country = countryCodes.find((c) => c.country_code === countryCode);
        return country ? country.dial_code : '';
    };

    const UpdateData = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            first_name: address.first_name,
            last_name: address.last_name,
            phone_number: address.phone_number,
            street_1: address.street_1,
            street_2: address.street_2,
            city: address.city,
            country: address.country,
            state: address.state,
            postcode: address.postcode,
        }

        axios.put('/v1/vendor/update', data)
            .then(res => {
                if (res.data.status === '200') {
                    toast.success(res.data.message);
                }
                else if (res.data.status === '409') {
                    toast.error(res.data.message);
                }
                else {
                    toast.error(res.data.message);
                }
            })
    }

    return (
        <>
            <form onSubmit={UpdateData}>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="card mb-5 mb-xl-10 col-lg-10 offset-lg-1">
                        <div className="card-header">
                            <div className="card-title">
                                <h3>Billing Address Information</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">First Name</label>
                                    <input name="first_name" className="form-control form-control-lg form-control-solid border border-2" value={address.first_name} onChange={handleInputChange} required />
                                    <span className="text-danger">{error.first_name}</span>
                                </div>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">Last Name</label>
                                    <input name="last_name" className="form-control form-control-lg form-control-solid border border-2" value={address.last_name} onChange={handleInputChange} required />
                                    <span className="text-danger">{error.last_name}</span>
                                </div>
                            </div>
                            <div className="fv-row mb-10">
                                <label className="fs-6 fw-semibold form-label required">Phone Number</label>
                                <div className="row">
                                    <div className="col-3">
                                        <select
                                            name="dialcode"
                                            className="form-select form-select-lg form-select-solid border border-2"
                                            value={dialCode}
                                            onChange={(e) => setDialCode(e.target.value)}
                                            required
                                            style={{ width: '100%' }}
                                        >
                                            {countryCodes.map((country, index) => (
                                                <option key={`${country.country_code}-${country.dial_code}-${index}`} value={country.dial_code}>
                                                    {country.dial_code}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-9">
                                        <input name="phone_number" className="form-control form-control-lg form-control-solid border border-2" placeholder="Phone Number" value={address.phone_number} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <span className="text-danger">{error.phone_number}</span>
                            </div>
                            <div className='row'>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">Street 1</label>
                                    <textarea name="street_1" className="form-control form-control-lg form-control-solid border border-2" rows={3} value={address.street_1} onChange={handleTextAreaChange} required ></textarea>
                                    <span className="text-danger">{error.street_1}</span>
                                </div>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label">Street 2</label>
                                    <textarea name="street_2" className="form-control form-control-lg form-control-solid border border-2" rows={3} value={address.street_2} onChange={handleTextAreaChange}></textarea>
                                </div>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">Country</label>
                                    {countriesData && (
                                        <Select
                                            className="form-control form-control-sm form-control-solid border border-2"
                                            classNamePrefix=""
                                            name="country"
                                            options={countriesData.countries.map((country: any) => ({
                                                value: country.country,
                                                label: country.country
                                            }))}
                                            value={{ value: address.country, label: address.country }}
                                            onChange={(selectedOption) => {
                                                const selectedCountry = selectedOption?.value || '';
                                                setAddress((prevState) => ({ ...prevState, country: selectedCountry, state: '' }));
                                                setSelectedCountry(selectedCountry);
                                                setSelectedState('');
                                            }}
                                            placeholder="Select Your Country"
                                            isClearable
                                            required
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    display: 'flex',
                                                    border: '0',
                                                    boxShadow: 'none',
                                                    backgroundColor: 'transparent',
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    fontWeight: '500',
                                                    color: 'var(--kt-input-solid-color)',
                                                }),
                                            }}
                                        />
                                    )}
                                    <span className="text-danger">{error.country}</span>
                                </div>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">State</label>
                                    <Select
                                        className="form-control form-control-sm form-control-solid border border-2"
                                        name="state"
                                        options={countriesData?.countries
                                            .filter((country: any) => country.country === selectedCountry)
                                            .flatMap((country: any) => country.states.map((state: any) => ({
                                                value: state.country, // Use the appropriate property for the state value
                                                label: state.country
                                            })))
                                        }
                                        value={{ value: address.state, label: address.state }}
                                        onChange={(selectedOption) => {
                                            const selectedState = selectedOption?.value || '';
                                            setAddress((prevState) => ({ ...prevState, state: selectedState }));
                                            setSelectedState(selectedState);
                                        }}
                                        placeholder="Select Your State"
                                        isClearable
                                        required
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                display: 'flex',
                                                border: '0',
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                fontWeight: '500',
                                                color: 'var(--kt-input-solid-color)',
                                            }),
                                        }}
                                    />
                                    <span className="text-danger">{error.state}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">City</label>
                                    <input name="city" className="form-control form-control-lg form-control-solid border border-2" value={address.city} onChange={handleInputChange} required />
                                    <span className="text-danger">{error.city}</span>
                                </div>
                                <div className="fv-row mb-10 col-md-6">
                                    <label className="form-label required">Postcode</label>
                                    <input name="postcode" className="form-control form-control-lg form-control-solid border border-2" value={address.postcode} onChange={handleInputChange} required />
                                    <span className="text-danger">{error.postcode}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-stack justify-content-center mb-9">
                            <button type="submit" className="btn btn-lg btn-primary fs-4">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        </>
    );
}

export default Billing;
