import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
  return (
    <>
      <div id='kt_app_footer' className='app-footer fixed-bottom'>
        <div className='app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3'>
          <div className='text-dark order-2 order-md-1'>
            <span className='text-muted fw-semibold me-1'>
              {new Date().getFullYear().toString()}&copy;
            </span>
            <Link
              to='#'
              target='_blank'
              className='text-gray-800 text-hover-primary'
            >
              Fruitbox
            </Link>
          </div>
        </div>
      </div>

    </>
  );
}

export default Footer;



{/* <div className="app-footer fixed-bottom py-3 z-index-1">
        <div className="container-fluid d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-semibold me-1">2023&copy;</span>
            <Link to="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</Link>
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <Link to="https://keenthemes.com" target="_blank" className="menu-link px-2">About</Link>
            </li>
            <li className="menu-item">
              <Link to="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</Link>
            </li>
            <li className="menu-item">
              <Link to="https://1.envato.market/EA4JP" target="_blank" className="menu-link px-2">Purchase</Link>
            </li>
          </ul>
        </div>
      </div> */}