import React, { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Image from '../../../My project.png';
import countriesData from '../../../../country.json';
import Navbar from './Navbar';
import countryCodes from '../../../../country_phone_code.json';
import Select from 'react-select';

const Configuration = () => {

    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [count, setCount] = useState('');
    const [ActiveTab, setActiveNavLink] = useState('1');
    const [checking, setChecking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [dialCode, setDialCode] = useState('');

    const [productInput, setProduct] = useState({
        domain: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        dialcode: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        street_1: '',
        street_2: '',
        plan_id: '',
        card_name: '',
        card_number: '',
        cvv: '',
        expiry: '',
    });

    const [error, setError] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        street_1: '',
    });

    const [paymenterror, setPaymentError] = useState({
        card_name: '',
        card_number: '',
        cvv: '',
        expiry: ''
    });

    const [productChecked, setProductChecked] = useState('');
    const [planData, setPlanData] = useState([
        {
            sku: '',
            name: '',
        }
    ]);

    useEffect(() => {
        axios.get('/get-plan')
            .then(res => {
                if (res.data.status === '200') {
                    const parsedData = JSON.parse(res.data.data);
                    const products = parsedData.data.productDetail.items;
                    setPlanData(products);
                    // if (products.length > 0) {
                    //     setProductChecked(products[0].name);
                    // }
                    if (products.length > 0) {
                        const defaultProductName = "Fruit Product"; // Specify the default product name here
                        const defaultProduct = products.find((product: { sku: string; name: string }) => product.name === defaultProductName);
                        if (defaultProduct) {
                            setProductChecked(defaultProduct.name);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        getDialCodeFromCountryCode().then((code) => {
            setDialCode(code);
        });
    }, []);

    const getIPAddress = async () => {
        const response = await fetch('https://fruitbox-backend.laraveldev.work/api/');
        const data = await response.json();
        return data.country_code;
    };

    const getDialCodeFromCountryCode = async () => {
        const countryCode = await getIPAddress();
        const country = countryCodes.find((c) => c.country_code === countryCode);
        return country ? country.dial_code : '';
    };

    const handleProductClick = (e: ChangeEvent<HTMLInputElement>) => {
        const productName = e.target.name;
        setProductChecked(productName);
        setProduct((prevState) => ({
            ...prevState,
            plan_id: productName,
        }));
    };

    const validateCurrentTab = () => {
        let isValid = true;
        const newError = {
            first_name: '',
            last_name: '',
            phone_number: '',
            city: '',
            postcode: '',
            country: '',
            state: '',
            street_1: '',
        };

        if (productInput.first_name === '') {
            newError.first_name = 'First name is required';
            isValid = false;
        }
        if (productInput.last_name === '') {
            newError.last_name = 'Last name is required';
            isValid = false;
        }
        if (productInput.phone_number === '') {
            newError.phone_number = 'Phone number is required';
            isValid = false;
        }
        else if (!/^\d{10}$/.test(productInput.phone_number)) {
            newError.phone_number = 'Phone number must be a 10 digit number';
            isValid = false;
        }
        if (productInput.city === '') {
            newError.city = 'City name is required';
            isValid = false;
        }
        if (productInput.postcode === '') {
            newError.postcode = 'Postcode is required';
            isValid = false;
        }
        if (productInput.country === '') {
            newError.country = 'Country name is required';
            isValid = false;
        }
        if (productInput.state === '') {
            newError.state = 'State is required';
            isValid = false;
        }
        if (productInput.street_1 === '') {
            newError.street_1 = 'Street is required';
            isValid = false;
        }

        setError(newError);
        return isValid;
    };

    const validateNextTab = () => {
        let isValid = true;
        const newPaymentError = {
            card_name: '',
            card_number: '',
            cvv: '',
            expiry: '',
        };

        if (productInput.card_name === '') {
            newPaymentError.card_name = 'Card name is required';
            isValid = false;
        }
        if (productInput.card_number === '') {
            newPaymentError.card_number = 'Card number is required';
            isValid = false;
        }
        else if (!/^\d{8,19}$/.test(productInput.card_number)) {
            newPaymentError.card_number = 'Card number must be between 8 and 19 digit number';
            isValid = false;
        }
        if (productInput.cvv === '') {
            newPaymentError.cvv = 'CVV is required';
            isValid = false;
        }
        else if (!/^\d{3,4}$/.test(productInput.cvv)) {
            newPaymentError.cvv = 'CVV must be a 3 or 4 digit number';
            isValid = false;
        }
        if (productInput.expiry === '') {
            newPaymentError.expiry = 'Expiry date is required';
            isValid = false;
        }
        else {
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;
            const [year, month] = (productInput.expiry ?? '').split('-').map(Number);
            if (year < currentYear) {
                newPaymentError.expiry = 'Expiry date must be in the future or in current month';
                isValid = false;
            } else if (year === currentYear && month < currentMonth) {
                newPaymentError.expiry = 'Expiry date must be in the future or in current month';
                isValid = false;
            }
        }

        setPaymentError(newPaymentError);
        return isValid;
    };

    const handleContinue = () => {
        if (ActiveTab == '2') {
            if (validateCurrentTab()) {
                setActiveNavLink((parseInt(ActiveTab) + parseInt('1')).toString());
            }
        }
        else if (ActiveTab === '4') {
            const selectedProduct = planData.find((product) => product.name === productChecked);
            if (selectedProduct && selectedProduct.sku === "fruit-product") {
                setActiveNavLink((parseInt(ActiveTab) + parseInt('1')).toString());
            } else if (validateNextTab()) {
                setActiveNavLink((parseInt(ActiveTab) + parseInt('1')).toString());
            }
        }
        else {
            setActiveNavLink((parseInt(ActiveTab) + parseInt('1')).toString());
        }
    };

    const handleBack = () => {
        if (ActiveTab > '1') {
            setActiveNavLink(ActiveTab => (parseInt(ActiveTab) - 1).toString());
            setPaymentError({ card_name: '', card_number: '', cvv: '', expiry: '' });
            setError({ first_name: '', last_name: '', phone_number: '', city: '', postcode: '', country: '', state: '', street_1: '' });
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setProduct({ ...productInput, [e.target.name]: e.target.value });
    };

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.persist();
        setProduct({ ...productInput, [e.target.name]: e.target.value });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const { value } = e.target;
        const newValue = value.replace(/[^a-z]/g, "").toLowerCase();
        const InvalidCharacters = /[^a-z]/.test(value);
        setProduct({ ...productInput, domain: newValue });
        if (InvalidCharacters) {
            setChecking(false);
            return;
        }
        setChecking(true);
        axios.get(`/v1/domain/check?domain_name=${newValue}`)
            .then(res => {
                if (res.data.count > 0) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                if (res.data.status == '401') {
                    toast.error('Your Session has been expired');
                    localStorage.removeItem('id');
                    localStorage.removeItem('token');
                    navigate('/');
                }
                setCount(res.data.count);
                setMessage(res.data.message);
                setChecking(false);
            });
    };

    const isDisabled = productInput.domain.trim().length === 0;
    const FreePlan = productInput.plan_id === "Fruit Product";

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const selectedPlan = planData.find((plan) => plan.name === productChecked);
        const country = countriesData.countries.find((country: any) => country.country === productInput.country);
        const data = {
            user_id: localStorage.getItem('id'),
            domain: productInput.domain,
            first_name: productInput.first_name,
            last_name: productInput.last_name,
            phone_number: productInput.phone_number,
            dialCode: dialCode,
            city: productInput.city,
            postcode: productInput.postcode,
            state: productInput.state,
            country: productInput.country,
            country_code: country?.country_code || '',
            region: country?.region || '',
            company: 'Fruit Box',
            street_1: productInput.street_1,
            street_2: productInput.street_2,
            plan_id: selectedPlan ? selectedPlan.sku : '',
            card_name: productInput.card_name,
            card_number: productInput.card_number,
            cvv: productInput.cvv,
            expiry: productInput.expiry,
            ip_address: ''
        }
        axios.post('v1/domain/update', data)
            .then(res => {
                if (res.data.status == '200') {
                    toast.success(res.data.message);
                    setActiveNavLink(ActiveTab => (parseInt(ActiveTab) + 1).toString());
                }
                else if (res.data.status == '400') {
                    toast.error('Something went wrong! Try again later!');
                }
                else if (res.data.status == '422') {
                    toast.error(res.data.message);
                }
                setLoading(false);
            });
    };

    return (
        <>
            {/* <Navbar /> */}
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-10" id="kt_create_account_stepper">
                        <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
                            <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
                                <div className="stepper-nav">
                                    <div className={`stepper-item ${ActiveTab === '1' ? 'current' : ''} ${ActiveTab > '1' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                        <div className="stepper-wrapper">
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className="stepper-number">1</span>
                                            </div>
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">Setup Domain</h3>
                                            </div>
                                        </div>
                                        <div className="stepper-line h-40px"></div>
                                    </div>
                                    <div className={`stepper-item ${ActiveTab === '2' ? 'current' : ''} ${ActiveTab > '2' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                        <div className="stepper-wrapper">
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className="stepper-number">2</span>
                                            </div>
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">Billing Address</h3>
                                            </div>
                                        </div>
                                        <div className="stepper-line h-40px"></div>
                                    </div>
                                    <div className={`stepper-item ${ActiveTab === '3' ? 'current' : ''} ${ActiveTab > '3' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                        <div className="stepper-wrapper">
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className="stepper-number">3</span>
                                            </div>
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">Choose Plan</h3>
                                            </div>
                                        </div>
                                        <div className="stepper-line h-40px"></div>
                                    </div>
                                    {productChecked !== 'Fruit Product' && (
                                        <div className={`stepper-item ${ActiveTab === '4' ? 'current' : ''} ${ActiveTab > '4' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                            <div className="stepper-wrapper">
                                                <div className="stepper-icon w-40px h-40px">
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <span className="stepper-number">4</span>
                                                </div>
                                                <div className="stepper-label">
                                                    <h3 className="stepper-title">Billing Details</h3>
                                                </div>
                                            </div>
                                            <div className="stepper-line h-40px"></div>
                                        </div>
                                    )}
                                    {productChecked === 'Fruit Product' ? (
                                        <div className={`stepper-item ${ActiveTab === '4' ? 'current' : ''} ${ActiveTab > '4' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                            <div className="stepper-wrapper">
                                                <div className="stepper-icon w-40px h-40px">
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <span className="stepper-number">4</span>
                                                </div>
                                                <div className="stepper-label">
                                                    <h3 className="stepper-title">Ready to Go</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`stepper-item ${ActiveTab === '5' ? 'current' : ''} ${ActiveTab > '5' ? 'completed' : ''}`} data-kt-stepper-element="nav">
                                            <div className="stepper-wrapper">
                                                <div className="stepper-icon w-40px h-40px">
                                                    <i className='stepper-check fas fa-check'></i>
                                                    <span className="stepper-number">5</span>
                                                </div>
                                                <div className="stepper-label">
                                                    <h3 className="stepper-title">Ready to Go</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={`stepper-item ${ActiveTab === (productChecked === 'Fruit Product' ? '5' : '6')}`} data-kt-stepper-element="nav">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card d-flex flex-row-fluid flex-center">
                            <form className="card-body py-20 w-100 mw-xl-700px px-9" noValidate id="kt_create_account_form" onSubmit={handleSubmit}>
                                <div className={`${ActiveTab === '1' ? 'current' : ''}`} data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="pb-10 pb-lg-15">
                                            <h2 className="fw-bold d-flex align-items-center text-dark">Select Your Domain</h2>
                                        </div>
                                        <div className="fv-row">
                                            <div className='col'>
                                                <div className="row">
                                                    <div className="col-5 fv-row">
                                                        <input className="form-control form-control-lg form-control-solid fw-bold border border-2" placeholder="fruitbox-maindomain.io/" disabled />
                                                    </div>
                                                    <div className={`col-7 fv-row position-relative ${ActiveTab === '1' ? 'selected' : ''}`}>
                                                        <input type="text" name="domain" className="form-control form-control-lg form-control-solid border border-2" placeholder="Domain Name" autoComplete='off' value={productInput.domain} onChange={handleChange} />
                                                        {checking &&
                                                            <div className="spinner-border h-15px w-15px align-middle text-gray-400 position-absolute end-0 bottom-0 me-7 mb-5" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                                {productInput.domain !== '' && message && <div className={`form-text text-end ${count > '0' ? "text-danger" : "text-success"}`}>{message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${ActiveTab === '2' ? 'current' : ''}`} data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="pb-10 pb-lg-12">
                                            <h2 className="fw-bold text-dark">Billing Address Information</h2>
                                        </div>
                                        <div className='row'>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">First Name</label>
                                                <input name="first_name" className="form-control form-control-lg form-control-solid border border-2" value={productInput.first_name} onChange={handleInputChange} required />
                                                <span className="text-danger">{error.first_name}</span>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">Last Name</label>
                                                <input name="last_name" className="form-control form-control-lg form-control-solid border border-2" value={productInput.last_name} onChange={handleInputChange} required />
                                                <span className="text-danger">{error.last_name}</span>
                                            </div>
                                        </div>
                                        <div className="fv-row mb-10">
                                            <label className="fs-6 fw-semibold form-label required">Phone Number</label>
                                            <div className="row">
                                                <div className="col-3">
                                                    <select
                                                        name="dialcode"
                                                        className="form-select form-select-lg form-select-solid border border-2"
                                                        value={dialCode}
                                                        onChange={(e) => setDialCode(e.target.value)}
                                                        required
                                                        style={{ width: '100%' }}
                                                    >
                                                        {countryCodes.map((country, index) => (
                                                            <option key={`${country.country_code}-${country.dial_code}-${index}`} value={country.dial_code}>
                                                                {country.dial_code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-9">
                                                    <input name="phone_number" className="form-control form-control-lg form-control-solid border border-2" placeholder="Phone Number" value={productInput.phone_number} onChange={handleInputChange} required />
                                                </div>
                                            </div>
                                            <span className="text-danger">{error.phone_number}</span>
                                        </div>
                                        <div className='row'>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">Street 1</label>
                                                <textarea name="street_1" className="form-control form-control-lg form-control-solid border border-2" rows={3} value={productInput.street_1} onChange={handleTextAreaChange} required ></textarea>
                                                <span className="text-danger">{error.street_1}</span>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label">Street 2</label>
                                                <textarea name="street_2" className="form-control form-control-lg form-control-solid border border-2" rows={3} value={productInput.street_2} onChange={handleTextAreaChange}></textarea>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">Country</label>
                                                {countriesData && (
                                                    <Select
                                                        className="form-control form-control-sm form-control-solid border border-2"
                                                        classNamePrefix=""
                                                        name="country"
                                                        options={countriesData.countries.map((country: any) => ({
                                                            value: country.country,
                                                            label: country.country
                                                        }))}
                                                        value={{ value: productInput.country, label: productInput.country }}
                                                        onChange={(selectedOption) => {
                                                            const selectedCountry = selectedOption?.value || '';
                                                            setProduct((prevState) => ({ ...prevState, country: selectedCountry, state: '' }));
                                                            setSelectedCountry(selectedCountry);
                                                            setSelectedState('');
                                                        }}
                                                        placeholder="Select Your Country"
                                                        noOptionsMessage={() => 'Select Your Country'}
                                                        isClearable
                                                        required
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                display: 'flex',
                                                                border: '0',
                                                                boxShadow: 'none',
                                                                backgroundColor: 'transparent',
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                color: 'var(--kt-input-solid-color)',
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                color: 'var(--kt-input-solid-color)',
                                                            }),
                                                        }}
                                                    />
                                                )}
                                                <span className="text-danger">{error.country}</span>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">State</label>
                                                <Select
                                                    className="form-control form-control-sm form-control-solid border border-2"
                                                    name="state"
                                                    options={countriesData?.countries
                                                        .filter((country: any) => country.country === selectedCountry)
                                                        .flatMap((country: any) => country.states.map((state: any) => ({
                                                            value: state.country, // Use the appropriate property for the state value
                                                            label: state.country
                                                        })))
                                                    }
                                                    value={{ value: productInput.state, label: productInput.state }}
                                                    onChange={(selectedOption) => {
                                                        const selectedState = selectedOption?.value || '';
                                                        setProduct((prevState) => ({ ...prevState, state: selectedState }));
                                                        setSelectedState(selectedState);
                                                    }}
                                                    placeholder="Select Your State"
                                                    isClearable
                                                    required
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            display: 'flex',
                                                            border: '0',
                                                            boxShadow: 'none',
                                                            backgroundColor: 'transparent',
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontWeight: '500',
                                                            color: 'var(--kt-input-solid-color)',
                                                        }),
                                                        input: (provided) => ({
                                                            ...provided,
                                                            color: 'var(--kt-input-solid-color)',
                                                        }),
                                                    }}
                                                />
                                                <span className="text-danger">{error.state}</span>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">City</label>
                                                <input name="city" className="form-control form-control-lg form-control-solid border border-2" value={productInput.city} onChange={handleInputChange} required />
                                                <span className="text-danger">{error.city}</span>
                                            </div>
                                            <div className="fv-row mb-10 col-md-6">
                                                <label className="form-label required">Postcode</label>
                                                <input name="postcode" className="form-control form-control-lg form-control-solid border border-2" value={productInput.postcode} onChange={handleInputChange} required />
                                                <span className="text-danger">{error.postcode}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${ActiveTab === '3' ? 'current' : ''}`} data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="pb-10 pb-lg-15">
                                            <h2 className="fw-bold text-dark">Select Your Plan</h2>
                                        </div>
                                        <div className="fv-row">
                                            <div className="row">
                                                {planData.length > 0 ? (
                                                    planData.map((product) => (
                                                        <div key={product.sku} className="col-lg-6">
                                                            <input type="radio" className="btn-check" name={product.name} id={`kt_create_account_form_account_type_${product.name}`}
                                                                checked={productChecked === product.name} onChange={handleProductClick} />

                                                            <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                                                                htmlFor={`kt_create_account_form_account_type_${product.name}`} >
                                                                <span className="svg-icon svg-icon-3x me-5">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor" />
                                                                        <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor" />
                                                                    </svg>
                                                                </span>
                                                                <span className="d-block fw-semibold text-start">
                                                                    <span className="text-dark fw-bold d-block fs-4 mb-2">{product.name}</span>
                                                                    <span className="text-muted fw-semibold fs-6">For more information, <br /> Click Here</span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className='text-center fs-1 fw-semibold text-muted' style={{ marginTop: '78px', marginBottom: '107px' }}>
                                                        No Products Available
                                                    </div>
                                                )}
                                            </div>
                                            {planData.length > 0 && (
                                                <div className="table-responsive border border-dashed rounded p-3 mt-1">
                                                    <table className="table">
                                                        <thead>
                                                            <tr className="fw-bold fs-6 text-gray-800 text-center border-0 bg-light rounded">
                                                                <th className="min-w-210px rounded-start text-start ps-6 fs-4">Features</th>
                                                                <th className="min-w-250px fs-4">Fruit Product</th>
                                                                <th className="min-w-250px rounded-end fs-4">Juice Product</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="border-bottom border-dashed">
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">Price</td>
                                                                <td>$0.00</td>
                                                                <td>$10.00/month</td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">Max_P</td>
                                                                <td>5</td>
                                                                <td>Unlimited</td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">Max_T</td>
                                                                <td>5</td>
                                                                <td>10</td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">Max_S</td>
                                                                <td>1</td>
                                                                <td>10</td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">AR</td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-success">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">AT</td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-success">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">V</td>
                                                                <td>Both</td>
                                                                <td>Both</td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">ST</td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-success">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr className="fw-semibold fs-6 text-gray-800 text-center">
                                                                <td className="text-start ps-6 fs-4">P</td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${ActiveTab === '4' && productChecked !== 'Fruit Product' ? 'current' : ''}`} data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <div className="pb-10 pb-lg-15">
                                            <h2 className="fw-bold text-dark">Payment Methods</h2>
                                        </div>
                                        <div className="d-flex flex-column mb-7 fv-row">
                                            <label className={`${(productChecked === 'Fruit Product') ? '' : (productChecked === 'Juice Product' ? 'required' : '')} fs-6 fw-semibold form-label mb-2`}>Name On Card</label>
                                            <input type="text" className="form-control form-control-solid border border-2" placeholder="Enter card name" name="card_name" onChange={handleInputChange} required disabled={FreePlan} />
                                            <span className="text-danger">{paymenterror.card_name}</span>
                                        </div>
                                        <div className="d-flex flex-column mb-7 fv-row">
                                            <label className={`${(productChecked === 'Fruit Product') ? '' : (productChecked === 'Juice Product' ? 'required' : '')} fs-6 fw-semibold form-label mb-2`}>Card Number</label>
                                            <input type="text" className="form-control form-control-solid border border-2" placeholder="Enter card number" name="card_number" onChange={handleInputChange} required disabled={FreePlan} />
                                            <span className="text-danger">{paymenterror.card_number}</span>
                                        </div>
                                        <div className="fv-row mb-10">
                                            <label className={`${(productChecked === 'Fruit Product') ? '' : (productChecked === 'Juice Product' ? 'required' : '')} d-flex align-items-center fs-6 fw-semibold form-label mb-2`}>CVV</label>
                                            <div className="position-relative">
                                                <input type="text" className="form-control form-control-solid border border-2" placeholder="CVV" name="cvv" onChange={handleInputChange} required disabled={FreePlan} />
                                                <span className="text-danger">{paymenterror.cvv}</span>
                                                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                                    <span className="svg-icon svg-icon-2hx">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M22 7H2V11H22V7Z" fill="currentColor" />
                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fv-row mb-10">
                                            <label className={`${(productChecked === 'Fruit Product') ? '' : (productChecked === 'Juice Product' ? 'required' : '')} fs-6 fw-semibold form-label mb-2`}>Expiration Date</label>
                                            <input type="month" className='form-control form-control-solid border border-2' name="expiry" onChange={handleInputChange} required disabled={FreePlan}></input>
                                            <span className="text-danger">{paymenterror.expiry}</span>
                                        </div>
                                    </div>
                                </div>
                                {ActiveTab === (productChecked === 'Fruit Product' ? '4' : '5') && (
                                    <div className="w-100">
                                        <div className="pb-8 pb-lg-10">
                                            <h2 className="fw-bold text-dark">Summary!</h2>
                                        </div>
                                        <div className="mb-0">
                                            {Object.entries(productInput).map(([key, value]) => {
                                                if (!value) {
                                                    return null;
                                                }

                                                if (key === 'plan_id') {
                                                    const selectedPlan = planData.find((plan) => plan.name === value || plan.name === productChecked);
                                                    const planName = selectedPlan ? selectedPlan.name : 'N/A';
                                                    return (
                                                        <div key={key} className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                                            <span className="text-muted pe-2">PLAN NAME</span>
                                                            <div className="m-0">{planName.toUpperCase()}</div>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={key} className="d-flex flex-stack fw-semibold fs-5 text-muted mb-4">
                                                            <span className="text-muted pe-2">{key.replace('_', ' ').toUpperCase()}</span>
                                                            <div className="m-0">{value.toUpperCase()}</div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                )}
                                {ActiveTab === (productChecked === 'Fruit Product' ? '5' : '6') && (
                                    <div className="w-100">
                                        <div className="jumbotron text-center">
                                            <img src={Image} alt="Image" className="h-auto" style={{ width: '250px' }} />
                                            <h2 className="display-3 mb-5">Congratulations</h2>
                                            <p className="lead mb-3 fw-bold">
                                                Your Domain has been Created Successfully.
                                                <br />
                                            </p>
                                            <p className="lead mb-2 fw-bold">
                                                We are glad to have you join us and hope your experience with us will be memorable.
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex flex-stack pt-10">
                                    <div className="mr-2">
                                        {productChecked === 'Fruit Product' && ActiveTab > '1' && ActiveTab < '5' && (
                                            <button type="button" className="btn btn-lg btn-primary" onClick={handleBack}>
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                                                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                Previous
                                            </button>
                                        )}
                                        {productChecked !== 'Fruit Product' && ActiveTab > '1' && ActiveTab < '6' && (
                                            <button type="button" className="btn btn-lg btn-primary" onClick={handleBack}>
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                                                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                Previous
                                            </button>
                                        )}
                                    </div>
                                    <div>
                                        {productChecked === 'Fruit Product' && (
                                            <>
                                                {ActiveTab === '4' && (
                                                    <button type="submit" className="btn btn-lg btn-primary">
                                                        {!loading && <span className='indicator-label'>Submit</span>}
                                                        {loading ? (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Processing...
                                                            </span>
                                                        ) : null}
                                                        {!loading && (
                                                            <span className="svg-icon svg-icon-3 ms-1">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                                    <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </button>
                                                )}
                                                {ActiveTab < '4' && (
                                                    <button type="button" className="btn btn-lg btn-primary" disabled={isDisabled || disabled} onClick={handleContinue}>
                                                        Continue
                                                        <span className="svg-icon svg-icon-3 ms-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        {productChecked !== 'Fruit Product' && (
                                            <>
                                                {ActiveTab === '5' && (
                                                    <button type="submit" className="btn btn-lg btn-primary">
                                                        {!loading && <span className='indicator-label'>Submit</span>}
                                                        {loading ? (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Processing...
                                                            </span>
                                                        ) : null}
                                                        {!loading && (
                                                            <span className="svg-icon svg-icon-3 ms-1">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                                    <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </button>
                                                )}
                                                {ActiveTab < '5' && (
                                                    <button type="button" className="btn btn-lg btn-primary" disabled={isDisabled || disabled} onClick={handleContinue}>
                                                        Continue
                                                        <span className="svg-icon svg-icon-3 ms-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Configuration;