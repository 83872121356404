import { useLayout } from '../../core'
import Footer from './Footer'

const FooterWrapper = () => {
  const { config } = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='app-footer mt-auto' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className='app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3'>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
    </div>
  )
}

export default FooterWrapper;