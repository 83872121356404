
import React from "react";
import { Link } from "react-router-dom";
import HeaderWrapper from "../../../../_metronic/layout/components/header/HeaderWrapper";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FooterWrapper from "../../../../_metronic/layout/components/footer/FooterWrapper";
import SVG2 from '../../../default-small.svg'

const UserDomain = () => {

    return (
        <>
            <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                    <Navbar />
                    <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                            <div className='d-flex flex-column flex-column-fluid'>
                                <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-gray-50 dark:bg-gray-800">
                                    <div className='app-sidebar-logo justify-content-center' id='kt_app_sidebar_logo' >
                                        <Link to='#'>
                                            <img alt='Logo' src={SVG2} className='h-30px app-sidebar-logo-default' />
                                        </Link>
                                    </div>
                                    <div className="text-center">
                                        <h1>Welcome to FruitBox</h1>
                                        <h1>Log in or Register with your Email to Continue</h1>
                                        <div className="d-flex justify-content-center mt-5">
                                            <Link to='/login' className="btn btn-lg btn-primary me-3 fs-3">Log in</Link>
                                            <Link to='/register' className="btn btn-lg btn-primary fs-3">Sign up</Link>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: '210px'}} className="position-absolute left-0 right-0 top-0"></div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserDomain;