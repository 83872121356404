import React from 'react'
import { Routes, Route } from 'react-router-dom'
import axios from 'axios'
import { Toaster } from 'react-hot-toast'

import Login from './components/Login'
import Registration from './components/Registration'
import ForgotPassword from './components/ForgotPassword'
import NewPassword from './components/NewPassword'
import AuthLayout from './AuthLayout'
import Otp from './components/Otp'
import SetPassword from './components/SetPassword'
import TokenCheck from './components/TokenCheck'
import Test from './components/test'
import UserDomain from './components/UserDomain'
import LinkedInLoginBridge from './components/LinkedInLoginBridge'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
    ; (config.headers as any).Authorization = token ? `Bearer ${token}` : ''
  return config
});

// php artisan serve --host=192.168.0.106 --port=8000

const AuthPage = () => {
  return (
    <>
      <div>
        <Toaster position='top-center' />
      </div>

      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Registration />} />
          <Route path='/set_your_password' element={<SetPassword />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path='/new_password' element={<NewPassword />} />
          <Route path='/one-time-password' element={<Otp />} />
        </Route>
        <Route index element={<UserDomain />} />
        <Route path='/emailverify/token/:token' element={<TokenCheck />} />
        <Route path='/test' element={<Test />} />
        <Route path='/linkedin-login-url' element={<LinkedInLoginBridge />} />
      </Routes>
    </>
  )
}

export { AuthPage }
