
import React, { useEffect, useState } from 'react';
import LinkedInIcon from '../../../../linkedin-icon.png';

const LinkedInLogin = () => {

    return (
        <>
        <button
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
            onClick={() => {
                const clientId = '77uewekb0ki4ou';
                const redirectUri = encodeURIComponent('https://fruitbox-frontend.jsframework.app/set-domain');
                const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=r_liteprofile%20r_emailaddress`;
                window.location.href = authUrl;
            }}
        >
            <img alt='Logo' src={LinkedInIcon} className='h-15px me-3' />
            Log in with LinkedIn
        </button>
        </>
    );
};

export default LinkedInLogin;
