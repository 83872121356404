/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import Dashboard from '../modules/auth/components/Dashboard';
import App from '../App';
import DomainConfiguration from '../modules/auth/components/DomainConfiguration';
import Thanks from '../modules/auth/components/Thanks';
import UserDomain from '../modules/auth/components/UserDomain';
import Billing from '../modules/auth/components/Billing';
import Plan from '../modules/auth/components/Plan';
import Invoice from '../modules/auth/components/Invoice';
import { Overview } from '../modules/accounts/components/Overview';
// import TokenCheck from '../modules/auth/components/TokenCheck';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { REACT_APP_API_BASE_URL } = process.env

const AppRoutes: FC = () => {
  console.log('2Rendering PrivateRoutes');
  const { currentUser } = useAuth()
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {currentUser ? (
            <>
              <Route path='auth/*' element={<Thanks />} />
              <Route index element={<Dashboard />} />
            </>
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              <Route index element={<UserDomain />} />
            </>
          )}
          <Route path='/*' element={<PrivateRoutes />} >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="set-domain" element={<DomainConfiguration />} />
            <Route path="bill" element={<Billing />} />
            <Route path="plan" element={<Plan />} />
            <Route path="invoice" element={<Invoice />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes;
