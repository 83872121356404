
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { log } from 'console';

const LinkedInLoginBridge = () => {

    const navigate = useNavigate();
    const [code, setCode] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [access_token, setToken] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [method, setMethod] = useState('1');

    useEffect(() => {
        const handleLinkedInLogin = async () => {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');
            setCode(code || null);
            console.log(code);

            if (code) {
                try {
                    axios.post('/get-linkedin-email', { code })
                        .then(res => {
                            
                            if (res.data.status == 200) {
                                setEmail(res.data.data.email);
                                setToken(res.data.data.token);
                                setProcessing(true);
                            }
                        })
                } catch (error) {
                    console.error('Error sending code to backend:', error);
                }
            }
        };
        handleLinkedInLogin();
    }, [navigate]);

    useEffect(() => {
        if (email && access_token) {
            handleLogin();
        }
    }, [email, access_token]);

    const handleLogin = () => {
        const data = { email, access_token, method };
        axios.post('/register', data)
            .then(async (res) => {
                if (res.data.status === '200') {
                    localStorage.setItem('id', res.data.id);
                    localStorage.setItem('token', access_token);
                    const promise = toast.success(res.data.message)
                    await promise
                    setTimeout(() => {
                        navigate('/set-domain');
                    }, 1800)
                    console.log('Email and token sent to backend successfully.');
                }
            })
            .catch(error => {
                // Handle error response from the backend
                console.error('Error sending email and token to backend:', error);
            });
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <p className="fs-1 fw-semi-bold">Processing...</p>
            </div>
        </>
    );
};

export default LinkedInLoginBridge;