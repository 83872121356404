import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Invoice = () => {

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState<number | null>(currentYear);

    const handleYearChange = (year: string | null) => {
        if (year) {
            setSelectedYear(parseInt(year));
        }
    };

    const handleDownloadInvoice = (orderID: string) => {
        // Implement the logic to download the invoice using the orderID
        // You can use libraries like FileSaver.js or the built-in browser download APIs
        // Example using FileSaver.js:
        // import { saveAs } from "file-saver";
        // const fileURL = generateInvoiceURL(orderID);
        // saveAs(fileURL, `invoice_${orderID}.pdf`);
    };

    const renderInvoiceTable = (): JSX.Element | null => {

        const invoiceData2023 = [
            {
                date: "Jan 10, 2022",
                orderID: "2022001",
                details: "Product 3",
                amount: "$120.00",
            },
            {
                date: "Mar 22, 2022",
                orderID: "2022002",
                details: "Product 4",
                amount: "$50.00",
            },
        ];

        let invoiceData: any[] = [];

        if (selectedYear === 2023) {
            invoiceData = invoiceData2023;
        }

        if (invoiceData.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center text-center fw-bold h-100">
                    <p className="text-muted my-4 fs-4">No Purchased Product Data Available</p>
                </div>
            );
        }

        return (
            <div className="table-responsive">
                <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                    <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                        <tr>
                            <th className="min-w-175px ps-9">Date</th>
                            <th className="min-w-150px px-0">Order ID</th>
                            <th className="min-w-350px">Details</th>
                            <th className="min-w-125px">Amount</th>
                            <th className="min-w-125px text-center">Invoice</th>
                        </tr>
                    </thead>
                    <tbody className="fs-6 fw-semibold text-gray-600">
                        {invoiceData.map((invoice) => (
                            <tr key={invoice.orderID}>
                                <td className="ps-9">{invoice.date}</td>
                                <td className="ps-0">{invoice.orderID}</td>
                                <td>{invoice.details}</td>
                                <td className="text-success">{invoice.amount}</td>
                                <td className="text-center">
                                    <button className="btn btn-light btn-sm btn-active-light-primary" onClick={() => handleDownloadInvoice(invoice.orderID)}>
                                        Download
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card my-4">
                        <div className="card-header card-header-stretch d-flex justify-content-between align-items-center">
                            <div className="card-title">
                                <h3 className="m-0 text-gray-800">Statements</h3>
                            </div>
                            <DropdownButton
                                id="year-dropdown"
                                title={selectedYear?.toString() || "Select Year"}
                                onSelect={(eventKey: string | null) => handleYearChange(eventKey)}
                            >
                                <Dropdown.Item eventKey={currentYear.toString()}>{currentYear}</Dropdown.Item>
                                <Dropdown.Item eventKey={(currentYear - 1).toString()}>{currentYear - 1}</Dropdown.Item>
                                {/* Add more Dropdown.Items for past years */}
                            </DropdownButton>
                        </div>
                        <div className="card-body p-0">
                            {renderInvoiceTable()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Invoice;